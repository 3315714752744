export default {
    id: '',
    isLogin: false, // 登录状态
    userName: '', // 登录用户名
    USER_NAME: '',
    name: '',
    wxOpenId: '',
    tabId: 1,
    tenantId: '',
    token: "",
    isAdmin: '',
    appDownloadUrl: "",
    enterpriseName: "",
    IsShowView: true,
    appCode: "",
    appTab: "",
    appId: 8,
    lang: 'cn',
    leftMenu: 'default', // default  setting
    membership: '', // 系统版本 默认试用版
    kfWechatUrl: '', // 客服
    wxOpenId: '', // wxopenid
    extendStatus: '',
    catTotal: "5",
    keepAlive: 0, // 1 缓存状态  0 不缓存,
    filter: {
        pageNo: '',
        pageSize: '',
        status: '',
        category_id: '',
    }
}